import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "personalisation"
    }}>{`Personalisation`}</h1>
    <p>{`Personalisation is used to create and manage programs for personal pricing of tickets.`}</p>
    <h2 {...{
      "id": "content"
    }}>{`Content`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Guide`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`API`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Retrieving personalisation data in clients.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-personalisation-docs-personalisation-client-integration-guide"
            }}>{`Client integration`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-personalisation-docs-personalisation-client-api"
            }}>{`Client API`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Retrieving privacy related data in personalisation, for GDPR compliance.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-personalisation-docs-personalisation-privacy-api"
            }}>{`Privacy API`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Administration of personalisation programs.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-personalisation-docs-personalisation-program-administration-api"
            }}>{`Program Administration API`}</a></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      